<template>

    <div class="module h-full flex p-0">

        <div class="w-2/5 p-4 pl-8 flex flex-col">
            <div class="h-12 flex items-center mb-2">
                <div class="module-title self-center mr-auto">SL</div>
                <div class="text-font-gray mr-2">Sort By</div>
                <div class="w-56 mr-2">
                    <multiselect label="name" v-model="sortBy" :options="sortOptions" />
                </div>
                <!-- <div class="w-32">
                    <multiselect label="name" v-model="sortOrder" :options="sortOrderOptions" />
                </div> -->
            </div>
            <sl-column @loadingSl="setLoading" class="" :sortBy="sortBy" :sortOrder="sortBy" />
        </div>
        
        <separator direction="vertical" />

        <smu-column :loadingSl="loadingSl" :sortBy="sortBy" :sortOrder="sortOrder" class="w-3/5 p-4 pr-8" />
        
    </div>

</template>

<script>

    const SlColumn = () => import('@/components/Globals/Pipeline/SlColumn')
    const SmuColumn = () => import('@/components/Globals/Pipeline/SmuColumn')
    import { state } from '@/store';

    export default {
        components: {
            SlColumn,
            SmuColumn
        },
        data() {
            return {
                sortBy: {name: 'Name', value: 'name', direction: 'asc'},
                sortOptions: this.$optionsFilterPipeline,
                sortOrder: {name: 'ASC', value: 'asc'},
                sortOrderOptions: [
                    {name: 'ASC', value: 'asc'},
                    {name:' DESC', value: 'desc'},
                ],
                loadingSl: true
            }
        },
        methods: {
            setLoading(val) { this.loadingSl = val }
        },
        computed: {
            params() { return state.globalParams },
            legendColors() { return state.slLegendColors },
        }
    }

</script>